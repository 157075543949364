<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-input--small .el-input__inner {
    height: 30px;
  }

  .w-15 {
    width: 14.6%;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    ruleList,
    saveRule,
    changeTop,
    deleteRule,
    typeList
  } from "@/api/web/rule.js";
  import {
    getUploadLimit,
    checkFile,
    uploadFiles
  } from "@/api/common/public.js";

  /**
   * 新闻公告
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "规章制度 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        query: {
          keyword: "",
          ruleTypeId: "",
          ruleSort: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20
        },
        checked: true,
        showmodal: false,
        list: [],
        typeList: [],
        ruleForm: {
          ruleId: "",
          ruleName: "",
          ruleInfo: "",
          ruleTypeId: "",
          ruleType: "",
          ruleSort: "",
          ruleUrl: ""
        },
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0,
        // 文件上传
        uploadStatus: "",
        uploadModule: "WebRule",
        uploadLimit: {
          ext: "",
          size: 0
        },
      };
    },
    mounted() {
      getUploadLimit({
        module: this.uploadModule
      }).then(res => {
        if (res.status) {
          this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
          this.uploadLimit["size"] = res.data.sizeLimit;
          console.log(this.uploadLimit)
        }
      });
      this.getList();
      this.getTypeList();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询文件列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        ruleList(this.query).then(res => {
          if (res.status) {
            let tempList = [];
            for (let item of res.data) {
              item.isTop = item.isTop == '1' ? true : false
              tempList.push(item);
            }
            this.list = tempList
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      getTypeList() {
        typeList().then(res => {
          if (res.status) {
            this.typeList = res.data
          }
        });
      },
      /** 修改状态值 */
      updateStatus(row) {
        changeTop({
          ruleId: row.ruleId,
          isTop: row.isTop ? '1' : '0'
        }).then((res) => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: "修改成功"
            });
          }
        })
      },
      /** 删除文件 */
      deleteRule(ruleId, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteRule(ruleId).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.getList()
          })
        })
      },
      /** 初始化编辑文件 */
      initEditForm(row) {
        this.showmodal = true
        this.ruleForm = {
          ruleId: row.ruleId,
          ruleName: row.ruleName,
          ruleInfo: row.ruleInfo,
          ruleTypeId: row.ruleTypeId,
          ruleType: row.ruleType,
          ruleSort: row.ruleSort,
          ruleUrl: row.ruleUrl
        };
      },
      /** 上传文件 */
      uploadFile(file) {
        var _this = this;
        file = file.raw;
        if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
          _this.uploadStatus = "loading";
          uploadFiles(_this.uploadModule, file).then(res => {
            if (res.status) {
              var file = res.data[0];
              if (file.status) {
                _this.ruleForm.ruleUrl = file.path;
                _this.$message({
                  type: "success",
                  message: "上传成功"
                });
              } else {
                _this.$message({
                  type: "error",
                  message: file.error
                });
              }
            }
            _this.uploadStatus = "";
          });
        }
      },
      /** 保存文件 */
      saveRuleForm() {
        if (this.checkForm()) {
          this.fillParam();
          // 提交
          saveRule(this.ruleForm).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '提交成功!'
              });
              this.getList();
              this.cleanForm();
            }
          })
        }
      },
      /** 校验数据 */
      checkForm() {
        if (this.ruleForm.ruleName == "") {
          this.checkAlert("文件名称不能为空")
          return false;
        }
        if (this.ruleForm.ruleInfo == "") {
          this.checkAlert("文件介绍不能为空")
          return false;
        }
        if (this.ruleForm.ruleTypeId == "") {
          this.checkAlert("请选择文件类型")
          return false;
        }
        return true;
      },
      /** 校验提醒 */
      checkAlert(message) {
        this.$message({
          type: 'error',
          message: message
        });
      },
      /** 提交前填充数据 */
      fillParam() {
        // 填充文件类型
        for (let item of this.typeList) {
          if (item.ruleTypeId == this.ruleForm.ruleTypeId) {
            this.ruleForm.ruleType = item.ruleType
          }
        }
      },
      /** 清空表单 */
      cleanForm() {
        this.showmodal = false
        Object.keys(this.ruleForm).forEach((key) => (this.ruleForm[key] = ''))
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">

            <div class="pb-3 check-table-top">
              <form class="flexList" style="flex: 1;">
                <input placeholder="输入关键字" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
                <b-button variant="info" class="btn flexList mr-2 condition btn-outline-info" @click="showmodal=true"><i
                    class="iconfont icon-plus-circle1 mr-2"></i>添加规章</b-button>
              </form>
            </div>

            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:4%"> 序号</th>
                    <th style="width:10%">规章类型</th>
                    <th>规章制度名称</th>
                    <th style="width: 8%;">发布人</th>
                    <th style="width: 7%;">发布时间</th>
                    <th style="width:2%">置顶</th>
                    <th style="width:3%">编辑</th>
                    <th style="width:3%">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.ruleType}}-{{obj.ruleSort}} </td>
                    <td><a class="font-blue" :href="BASE_FILE_URL+obj.ruleUrl">{{obj.ruleName}}</a></td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{obj.createTime != null ? obj.createTime.substring(0,10) : ""}}</td>
                    <td class="tab-icon">
                      <b-form-checkbox v-model="obj.isTop" @change="updateStatus(obj)" switch class=" switch-check">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two" @click="initEditForm(obj)"></i></td>
                    <td class="tab-icon" @click="deleteRule(obj.ruleId,obj.ruleName)"><i
                        class="iconfont icon-riLine-delete-bin-line"></i></td>
                  </tr>
                </tbody>


              </table>

            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="perPage" layout="total, sizes,jumper, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal id="uploadRule" v-model="showmodal" centered title="文件上传" size="lg" title-class="font-18" hide-footer>
      <form class="w-100  p-3">
        <div class="form-group flexList">
          <div class="operation-text">制度名称<span style="color: red;">*</span></div>
          <input type="text" v-model="ruleForm.ruleName" class="form-control w-40 h30 mr-2">
        </div>
        <div class="form-group flexList">
          <div class="operation-text">规章类型<span style="color: red;">*</span></div>
          <el-select class=" w-40  mr-2 " placeholder="规章类型" v-model="ruleForm.ruleTypeId" size="small">
            <el-option :value="item.ruleTypeId" v-for="item in typeList" :key="item.ruleTypeId" :label="item.ruleType">
              {{item.ruleType}}
            </el-option>
          </el-select>

          <el-select name="ruleSort" id="ruleSort" class=" w-40" size="small" v-model="ruleForm.ruleSort">
            <el-option value="申请表">申请表</el-option>
            <el-option value="数据样表">数据样表</el-option>
            <el-option value="模板">模板</el-option>
            <el-option value="其他">其他</el-option>
          </el-select>
        </div>

        <div class="form-group flexList">
          <div class="operation-text">规章简介&nbsp;</div>
          <textarea name="fileInfo" id="fileInfo" rows="6" v-model="ruleForm.ruleInfo" class="form-control"
            style="width: 81%;"></textarea>
        </div>

        <div class="form-group mb-2 flexList">
          <div class="operation-text">上传规章<span style="color: red;">*</span></div>
          <a v-if="ruleForm.ruleUrl" class="colorA mr-2" :href="BASE_FILE_URL + ruleForm.ruleUrl" target="_blank"
            style="display:block;">查看上传文件
          </a>
          <!--<el-form-item label="" prop="ruleUrl" style="width:50%;">-->
          <el-upload class="upload-demo" ref="upload" action="" :on-change="uploadFile" :show-file-list="false"
            :accept="uploadLimit.ext" :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">
              <span name="default" v-if="uploadStatus != 'loading'">选取文件</span>
              <span v-if="uploadStatus == 'loading'">上传中<i class="el-icon-loading"></i></span>
            </el-button>
          </el-upload>

        </div>
        <div class=" flexList">
          <div style="width: 10%;"></div>
          <el-tag type="danger" class="d-block">
            支持上传{{ uploadLimit.ext }}文件，且不超过{{ uploadLimit.size/1024/1024 }}M</el-tag>
        </div>
        <div class="mt-3 text-center">
          <!--<p style="color: red;">提示： 文件上传的类型可以是：-->
          <!--jpg,jpeg,png,txt,doc,docx,pdf,xls,xlsx,zip,rar,ppt,pptx。</p>-->
          <button type="button" class="btn btn-info h30 w-sm mr-2" @click="saveRuleForm()">上传规章
          </button>
          <button type="button" class="btn btn-secondary h30 w-sm " @click="cleanForm()">取消上传
          </button>
        </div>
      </form>
    </b-modal>

    <!-- 弹窗结束 -->
  </Layout>
</template>
