import { getRequest, postRequest, downloadRequest, patchRequest, deleteRequest } from "@/libs/axios";

// ------------------------规章制度管理-----------------------
// 规章制度列表
export function ruleList(params) {
    return getRequest("/yethan/web/rule/listPage", params);
}
// 规章制度修改状态
export function changeTop(params) {
    return patchRequest("/yethan/web/rule/changeTop", params);
}
// 删除规章制度
export function deleteRule(sid) {
    return deleteRequest("/yethan/web/rule/"+sid);
}
// 规章制度分类列表
export function typeList(params) {
    return getRequest("/yethan/web/rule/typeList", params);
}
// 保存规章制度
export function saveRule(params) {
    return postRequest("/yethan/web/rule", params);
}
